'use client'

import { Box } from '@design-system/src/components/Box'
import { Button } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { CustomGrid } from '@design-system/src/components/CustomGrid'
import { Gutter } from '@design-system/src/components/Gutter'
import { Icon } from '@design-system/src/components/Icon'
import { Skeleton } from '@design-system/src/components/Skeleton'
import { Text } from '@design-system/src/components/Text'
import { Textfield } from '@design-system/src/components/Textfield'
import { CheckIcon, Cross1Icon, PlusIcon } from '@radix-ui/react-icons'
import { useSession } from 'next-auth/react'
import { FC, useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { API_ROUTES_NEXT_PUBLIC_SITE } from 'src/utils/route-utils'
import { css, cva } from 'styled-system/css'

import { BlockPubNewslettersCustomPageData } from '../../../_utils/typescript-utils'

type FormInput = {
  email?: string
  selectedNewsletterIds: string[]
}

export const Newsletters: FC<{
  availableNewsletters?: BlockPubNewslettersCustomPageData['availableNewsletters']
}> = ({ availableNewsletters }) => {
  const { data: session, status, update } = useSession()
  const [apiCallFailed, setApiCallFailed] = useState<boolean>(false)
  const [subscribeSuccess, setSubscribeSuccess] = useState<boolean>(false)
  const [apiCallInProgress, setApiCallInProgress] = useState<boolean>(false)
  const userNewsletterSubscriptionIds = session?.user.newsletterSubscriptions?.map(n => n.newsletterId) ?? []
  const sessionEmail = session?.user.email

  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { isDirty, errors },
  } = useForm<FormInput>({
    mode: 'onSubmit',
    defaultValues: {
      email: sessionEmail,
      selectedNewsletterIds: [],
    },
  })

  useEffect(() => {
    if (status === 'authenticated') {
      reset({
        email: sessionEmail,
        selectedNewsletterIds: [],
      })
    }
  }, [status])

  const onToggleSelected = useCallback(
    (newsletterId: string) => () => {
      if (subscribeSuccess) setSubscribeSuccess(false)
      if (apiCallFailed) setApiCallFailed(false)
      const selectedNewsletterIds = watch('selectedNewsletterIds')
      const updatedSelectedNewsletterIds = selectedNewsletterIds.includes(newsletterId)
        ? selectedNewsletterIds.filter(id => id !== newsletterId)
        : [...selectedNewsletterIds, newsletterId]
      setValue('selectedNewsletterIds', updatedSelectedNewsletterIds, { shouldDirty: true })
    },
    [watch, setValue, subscribeSuccess, apiCallFailed],
  )

  if (status == 'loading')
    return (
      <CustomGrid
        columns={{
          base: '1',
          bp1: '2',
          bp3: '3',
        }}
        gap={{
          base: 'small',
        }}>
        <Skeleton verticalSpacing={'medium'} height={'xLarge'} />
        <Skeleton verticalSpacing={'medium'} height={'xLarge'} />
        <Skeleton verticalSpacing={'medium'} height={'xLarge'} />
        <Skeleton verticalSpacing={'medium'} height={'xLarge'} />
        <Skeleton verticalSpacing={'medium'} height={'xLarge'} />
        <Skeleton verticalSpacing={'medium'} height={'xLarge'} />
      </CustomGrid>
    )

  const onSubmit = async (form: FormInput) => {
    setApiCallInProgress(true)
    setApiCallFailed(false)
    if (!form.email) {
      throw new Error('No user email found')
    }

    const newslettersToSubscribeIds: string[] = form.selectedNewsletterIds.filter(
      id => !userNewsletterSubscriptionIds.includes(id),
    )
    const newslettersToUnsubscribeIds: string[] = form.selectedNewsletterIds.filter(id =>
      userNewsletterSubscriptionIds.includes(id),
    )

    try {
      if (!!newslettersToSubscribeIds.length) {
        const dataSubscriptionsToAdd = new URLSearchParams()
        dataSubscriptionsToAdd.append('email', form.email)
        dataSubscriptionsToAdd.append('newsletterIds', newslettersToSubscribeIds.join(','))
        const resSubscribe = await fetch(API_ROUTES_NEXT_PUBLIC_SITE.subscribeToNewsletterApiUrl, {
          method: 'post',
          body: dataSubscriptionsToAdd,
        })
        const resSubscribeJson = await resSubscribe.json()
      }
      if (!!newslettersToUnsubscribeIds.length) {
        const dataSubscriptionsToRemove = new URLSearchParams()
        dataSubscriptionsToRemove.append('email', form.email)
        dataSubscriptionsToRemove.append('newsletterIds', newslettersToUnsubscribeIds.join(','))
        const resUnsubscribe = await fetch(API_ROUTES_NEXT_PUBLIC_SITE.unsubscribeFromNewsletterApiUrl, {
          method: 'post',
          body: dataSubscriptionsToRemove,
        })
        const resUnsubscribeJson = await resUnsubscribe.json()
      }
      await update()
      setSubscribeSuccess(true)
      reset(
        {
          email: form.email,
          selectedNewsletterIds: [],
        },
        { keepDefaultValues: false },
      )
    } catch (error) {
      setApiCallFailed(true)
      reset()
    } finally {
      setApiCallInProgress(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {subscribeSuccess && (
        <Text
          variant="body2"
          onClick={() => {
            setSubscribeSuccess(false)
          }}
          css={{
            color: '$sucText',
            backgroundColor: '$suc',
            borderRadius: '$3',
            py: '$2',
            px: '$5',
            mb: '$8',
            mr: '$auto',
            ml: '$auto',
            textAlign: 'center',
            position: 'relative',
            transition: 'ease-in-out',
            transitionDuration: '$normal',
            transitionProperty: 'all',
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: '$suc_L',
            },
          }}>
          {status === 'authenticated'
            ? 'Your changes have been saved!'
            : 'An email has been sent to confirm your subscriptions!'}
          <Icon
            reactIcon={<Cross1Icon />}
            size={14}
            css={{
              position: 'absolute',
              right: '$2',
              top: '[50%]',
              p: '$1',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
              borderColor: '$sucText',
              borderStyle: 'solid',
              borderRadius: '$3',
              borderWidth: '$1',
            }}
          />
        </Text>
      )}
      {!!(availableNewsletters?.length && availableNewsletters.length > 1) && (
        <CustomFlex gap="4" css={{ mb: '$8' }}>
          <Button
            variant="tertiary"
            label="Unsubscribe from all"
            onClick={() => {
              if (subscribeSuccess) setSubscribeSuccess(false)
              if (apiCallFailed) setApiCallFailed(false)
              const idsOfNewslettersSubscribed =
                availableNewsletters?.filter(n => userNewsletterSubscriptionIds.includes(n.id)).map(n => n.id) ?? []
              setValue('selectedNewsletterIds', idsOfNewslettersSubscribed, { shouldDirty: true })
            }}
          />
          <Button
            variant="primary"
            label="Subscribe to all"
            onClick={() => {
              if (subscribeSuccess) setSubscribeSuccess(false)
              if (apiCallFailed) setApiCallFailed(false)
              const idsOfNewslettersNotSubscribed =
                availableNewsletters?.filter(n => !userNewsletterSubscriptionIds.includes(n.id)).map(n => n.id) ?? []
              setValue('selectedNewsletterIds', idsOfNewslettersNotSubscribed, { shouldDirty: true })
            }}
          />
        </CustomFlex>
      )}
      <CustomGrid
        columns={{
          base: '1',
          bp1: '2',
          bp3: '3',
        }}
        gap={{
          base: 'small',
          bp1: 'medium',
        }}>
        {availableNewsletters?.map(newsletter => {
          const isUserSubscribedToNewsletter = userNewsletterSubscriptionIds.includes(newsletter.id)
          const isSelectedByUser = watch('selectedNewsletterIds').includes(newsletter.id)
          const currentDisplayStateIsSubscribed =
            (isUserSubscribedToNewsletter && !isSelectedByUser) || (!isUserSubscribedToNewsletter && isSelectedByUser)
          const label = currentDisplayStateIsSubscribed ? 'Remove' : 'Add'
          const variant = currentDisplayStateIsSubscribed ? 'tertiary' : 'primary'
          const reactIcon = currentDisplayStateIsSubscribed ? <Cross1Icon /> : <PlusIcon />

          return (
            <div key={newsletter.id} className={cardWrapper}>
              {isSelectedByUser && (
                <Icon
                  css={css.raw({ position: 'absolute', top: '$1', right: '$2', color: '$pri' })}
                  reactIcon={<CheckIcon />}
                  size={40}
                />
              )}
              <div className={cardMainContentWrapper}>
                <Text variant="h6">{newsletter.name}</Text>
                <Text variant="body2" css={{ mt: '$4', color: '$gs11' }}>
                  {newsletter.description}
                </Text>
              </div>
              <CustomFlex justify="end" css={{ mt: '$8' }}>
                <Button
                  variant={variant}
                  label={label}
                  onClick={onToggleSelected(newsletter.id)}
                  reactIcon={reactIcon}
                />
              </CustomFlex>
            </div>
          )
        })}
      </CustomGrid>
      <div
        className={validationWrapper({
          visible: watch('selectedNewsletterIds').length !== 0,
        })}>
        <Gutter variant={'bare'}>
          <CustomFlex justify="center" direction={'column'} gap="3" align={'center'}>
            {status === 'unauthenticated' && (
              <Box css={{ mb: '$3', width: '$full' }}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: 'Email is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Please enter a valid email address',
                    },
                  }}
                  render={({ field }) => (
                    <Textfield
                      centerText={true}
                      {...field}
                      placeholder="john.doe@gmail.com"
                      type="email"
                      reactFormErrors={errors}
                      fullWidth={true}
                      // floatingLabel="* email"
                    />
                  )}
                />
              </Box>
            )}
            <Button
              disabled={!isDirty}
              label={`Validate my newsletters (${watch('selectedNewsletterIds').length})`}
              variant={'secondary'}
              buttonType="submit"
              fullWidth={true}
              buttonState={apiCallInProgress ? 'waiting' : 'default'}
            />
            {apiCallFailed && (
              <Text
                variant="caption"
                css={{
                  color: '$aleText',
                  backgroundColor: '$ale',
                  borderRadius: '$3',
                  p: '$2',
                  mt: '$2',
                  width: '[fit-content]',
                }}>
                An error occurred. Please try again later.
              </Text>
            )}
          </CustomFlex>
        </Gutter>
      </div>
    </form>
  )
}

const cardWrapper = css({
  backgroundColor: '$gs1',
  padding: '$6',
  boxSizing: 'border-box',
  borderRadius: '$3',
  boxShadow: '$around',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
})
const cardMainContentWrapper = css({
  flexGrow: 1,
})

const validationWrapper = cva({
  base: {
    minHeight: '[200px]',
    backgroundColor: '$gs1',
    position: 'fixed',
    transitionDuration: '$normal',
    transitionProperty: '[all]',
    transitionTimingFunction: 'ease-in-out',
    boxShadow: '$top',
    boxSizing: 'border-box',
    padding: '$8',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  variants: {
    visible: {
      true: {
        transform: 'translateY(0)',
      },
      false: {
        transform: 'translateY(100%)',
      },
    },
  },
  defaultVariants: {
    visible: false,
  },
})
