import { ButtonProps } from '@design-system/src/components/Button'
import { getStripe } from '@design-system/src/utils/stripe-utils'
import { BlockPubSubscribeCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import React, { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import type { SystemStyleObject } from 'styled-system/types'

import { WysiwygElement } from '@models/wysiwyg-types'

import { postData } from '../../../../_utils/rest-utils'
import { PlanCard, StripePlanFrequencyLabel } from '../SubscibeShared/PlanCard'

export type StripePlanCard = {
  planSummary?: string
  benefits?: WysiwygElement[]
  priceSubtitle?: string
  isTopChoice: boolean
  name?: string
  subtitle?: string
  priceForYearlyToggleChoice?: number
  priceForMonthlyToggleChoice?: number
  struckOutPriceForYearlyToggleChoice?: number | null
  struckOutpriceForMonthlyToggleChoice?: number | null
  stripePriceIdMonthly: string
  stripePriceIdYearly: string
  productId: string
  userHasASubscription: boolean
  planType: 'yearly' | 'monthly'
  css?: SystemStyleObject
  loadStripe: BlockPubSubscribeCustomPageData['loadStripe']
  createSubscriberWithStripeAccountApiUrl: BlockPubSubscribeCustomPageData['createSubscriberWithStripeAccountApiUrl']
  updateSubscriberWithStripeAccountApiUrl: BlockPubSubscribeCustomPageData['updateSubscriberWithStripeAccountApiUrl']
  status: BlockPubSubscribeCustomPageData['status']
  stripePublishableKey: BlockPubSubscribeCustomPageData['stripePublishableKey']
  userIsSubscribedToPlanAndPrice: boolean
  totalNumberOfPlans: 1 | 2 | 3
  showYearlySavingsInCents: boolean
  clickSubscribeLinkOverride?: string
  // handleSubscribeToPlan: (props:{email?: string, plan: PropaySubscriptionPlan |SubscriptionPlan}) => Promise<void>
}

interface IFormInput {
  email: string
}

export const StripePlanCard: React.FC<StripePlanCard> = ({
  benefits,
  css: cssProp = {},
  updateSubscriberWithStripeAccountApiUrl,
  priceSubtitle,
  isTopChoice,
  name,
  subtitle,
  priceForYearlyToggleChoice,
  priceForMonthlyToggleChoice,
  struckOutpriceForMonthlyToggleChoice,
  struckOutPriceForYearlyToggleChoice,
  planSummary,
  planType,
  stripePriceIdMonthly,
  stripePriceIdYearly,
  userHasASubscription,
  createSubscriberWithStripeAccountApiUrl,
  status,
  stripePublishableKey,
  loadStripe,
  userIsSubscribedToPlanAndPrice,
  totalNumberOfPlans,
  showYearlySavingsInCents,
  clickSubscribeLinkOverride,
}) => {
  const [submitBtnState, setSubmitBtnState] = useState<ButtonProps['buttonState']>('default')
  const [errorMsg, setErrorMsg] = useState('')

  let planPrice = planType === 'yearly' ? priceForYearlyToggleChoice : priceForMonthlyToggleChoice
  let planStruckOutPrice =
    planType === 'yearly' ? struckOutPriceForYearlyToggleChoice : struckOutpriceForMonthlyToggleChoice
  let planFrequencyLabel: StripePlanFrequencyLabel = planType === 'yearly' ? 'Year' : 'Month'
  let priceId = planType === 'yearly' ? stripePriceIdYearly : stripePriceIdMonthly

  const yearlySavingsInCents =
    showYearlySavingsInCents && planType === 'yearly' && priceForMonthlyToggleChoice && priceForYearlyToggleChoice
      ? priceForMonthlyToggleChoice * 12 - priceForYearlyToggleChoice
      : undefined

  const onSubscribeToPlan = async (props: { email?: string }) => {
    const { email } = props
    setSubmitBtnState('waiting')
    if (userHasASubscription) {
      await changeSubscriptionPlan(priceId)
    } else {
      await firstTimeSubscribing(priceId, email)
    }
    //  Subcribe the user (user has no current plans)
    setSubmitBtnState('default')
  }

  const firstTimeSubscribing = async (priceId: string, email?: string) => {
    try {
      type SubriptionDataType = {
        price: string
        email?: string
      }
      const data: SubriptionDataType = {
        price: priceId,
      }
      if (email) {
        data.email = email
      }
      const { accountId, sessionId } = await postData<
        SubriptionDataType,
        {
          sessionId: string
          accountId: string
        }
      >({
        url: createSubscriberWithStripeAccountApiUrl,
        data,
      })
      const stripe = await getStripe(accountId, stripePublishableKey, loadStripe)
      if (stripe) {
        stripe.redirectToCheckout({ sessionId })
      } else {
        throw new Error('Failed to load stripe')
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message =
        typeof error === 'object' && error?.cause?.name === 'USER_ALREADY_EXISTS'
          ? 'Subscription with email already exists.'
          : 'An error occurred. Please try again later.'
      setErrorMsg(message)
    }
  }

  const changeSubscriptionPlan = async (priceId: string) => {
    //  Open stripe portal to change subscription
    try {
      type SubriptionDataType = {
        price: string
      }
      const data: SubriptionDataType = {
        price: priceId,
      }
      const { url, error } = await postData<
        SubriptionDataType,
        {
          url: string
          error: {
            message: string
          }
        }
      >({
        url: updateSubscriberWithStripeAccountApiUrl,
        data,
      })
      if (error) {
        throw new Error(error.message)
      }
      window.location.assign(url)
    } catch (error) {
      setErrorMsg('An error occured while changing subscription plan. Please try again later.')
    }
    setSubmitBtnState('default')
  }

  const onSubmitEmail: SubmitHandler<IFormInput> = async formData => {
    const email = formData.email
    onSubscribeToPlan({ email })
  }

  if (!planPrice) return null

  return (
    <PlanCard
      clickSubscribeLinkOverride={clickSubscribeLinkOverride}
      benefits={benefits}
      css={cssProp}
      priceSubtitle={priceSubtitle}
      isTopChoice={isTopChoice}
      name={name}
      subtitle={subtitle}
      planSummary={planSummary}
      userIsSubscribedToPlanAndPrice={userIsSubscribedToPlanAndPrice}
      onSubmitUserInfo={onSubmitEmail}
      onSubscribeToPlan={onSubscribeToPlan}
      errorMsg={errorMsg}
      subscriptionInProgress={submitBtnState === 'waiting'}
      planPrice={planPrice}
      planStruckOutPrice={planStruckOutPrice || undefined}
      planFrequencyLabel={planFrequencyLabel}
      isUserUnauthenticated={status === 'unauthenticated'}
      planRequiresShipping={false}
      totalNumberOfPlans={totalNumberOfPlans}
      secondaryFrequencyLabel="month"
      yearlySavingsInCents={yearlySavingsInCents}
    />
  )
}
