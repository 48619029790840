'use client'

import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import { useSession } from 'next-auth/react'
import 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React, { FC } from 'react'
import { PAGES_NEXT_PUBLIC_SITES } from 'src/utils/route-utils'

import { arePublicationsAvailable, isUserSubscribedToPublication } from '../../../_utils/publication-utils'
import { BlockPubNavSqueezeCustomPageData } from '../../../_utils/typescript-utils'

export const SubcribeAndSignIn: React.FC<{
  stripePlans: BlockPubNavSqueezeCustomPageData['stripePlans']
  stripeSupportPlans: BlockPubNavSqueezeCustomPageData['stripeSupportPlans']
  isSubscribePage: BlockPubNavSqueezeCustomPageData['isSubscribePage']
  propayPlans: BlockPubNavSqueezeCustomPageData['propayPlans']
  connectedSubscriptionSystem: BlockPubNavSqueezeCustomPageData['connectedSubscriptionSystem']
}> = ({ stripePlans, stripeSupportPlans, isSubscribePage, connectedSubscriptionSystem, propayPlans }) => {
  const pathname = usePathname()
  const { data: session, status } = useSession()

  const userIsSubscribed = isUserSubscribedToPublication({
    status,
    session,
    stripePlans,
    stripeSupportPlans,
    connectedSubscriptionSystem,
  })
  const publicationsAreAvailable = arePublicationsAvailable({
    stripePlans,
    stripeSupportPlans,
    propayPlans,
    connectedSubscriptionSystem,
  })
  const showSubscribeButton = !userIsSubscribed && publicationsAreAvailable && !isSubscribePage
  const showLoginButton = status === 'unauthenticated' && isSubscribePage

  return (
    <>
      {pathname !== PAGES_NEXT_PUBLIC_SITES.thankYou && (
        <ButtonComp
          visible={showSubscribeButton}
          question="Don't have a subscription?"
          action="Subscribe"
          actionUrl="/subscribe"
        />
      )}
      <ButtonComp
        visible={showLoginButton}
        question="Already have a subscription?"
        action="Sign In"
        actionUrl="/sign-in"
      />
    </>
  )
}

const ButtonComp: FC<{
  visible: boolean
  question: string
  action: string
  actionUrl: string
}> = ({ visible, question, action, actionUrl }) =>
  visible ? (
    <CustomFlex
      css={{
        width: 'fit',
        whiteSpace: 'noWrap',
        display: 'flex',
      }}>
      <Text variant="body2" css={{ color: '$gs12', display: 'none', bp3: { display: 'inline-block' } }}>
        {question}&nbsp;
      </Text>
      <Link href={actionUrl} prefetch={true}>
        <Text
          variant="body2"
          css={{
            color: '$pri',
            _hover: {
              opacity: '0.8',
              transitionProperty: 'opacity',
              transitionDuration: '$normal',
              transitionTimingFunction: 'in-out',
            },
          }}>
          {action}
        </Text>
      </Link>
    </CustomFlex>
  ) : null
