'use client'

import { Box } from '@design-system/src/components/Box'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { CustomGrid } from '@design-system/src/components/CustomGrid'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { WysiwygReader } from '@design-system/src/components/WysiwygReader'
import { BlockPubArticleRelatedArticlesCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import React from 'react'

import { isAdvertisementVisible } from '../../_utils/ad-utils'
import { BlockViewProps } from '../../_utils/typescript-utils'
import { ArticlePreviewVerticalSmall } from '../ArticlePreviewVerticalSmall'
import { GoogleAd } from '../GoogleAd'

const collapsableId = 'articles-related-articles-ad'

export const BlockPubArticleRelatedArticles: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: BlockPubArticleRelatedArticlesCustomPageData
  }>
> = props => {
  const { articlesForArticlesRelatedArticles, googleAdConfig, storyFooterWysiwygContent } = props.blockCustomData
  const advertisementIsVisible = isAdvertisementVisible(googleAdConfig)

  return articlesForArticlesRelatedArticles.length ? (
    <div data-sho-block-name="BlockPubArticleRelatedArticles" data-sho-block-id={props.blockId}>
      {storyFooterWysiwygContent && (
        <Gutter variant="horizontal" css={{ mb: '$5', display: 'flex', justifyContent: 'center' }}>
          <WysiwygReader initialValue={storyFooterWysiwygContent} css={{ maxWidth: '750px' }} />
        </Gutter>
      )}
      {advertisementIsVisible && (
        <Gutter
          css={{
            bgColor: '$gs1',
            overflowX: 'auto',
            bp3: {
              display: 'block',
            },
          }}
          id={collapsableId}>
          <GoogleAd {...googleAdConfig} collapsableId={collapsableId} />
        </Gutter>
      )}
      <Gutter variant="horizontal">
        <Box css={{ borderBottomWidth: '$1', borderBottomStyle: 'solid', borderBottomColor: '$gs8' }} />
      </Gutter>
      <Gutter>
        <CustomFlex
          justify={{ base: 'center', bp3: 'between' }}
          align="center"
          css={{ mb: '$6', bp1: { mb: '$8' }, bp3: { mb: '$10' } }}>
          <Text variant="h6">Related</Text>
        </CustomFlex>
        <CustomGrid
          css={{ overflowX: 'auto', overflowY: 'hidden' }}
          columns={{ base: '1', 'bp1.5': '2', bp2: '4' }}
          gap={{ base: 'small', bp1: 'medium', bp3: 'large' }}>
          {articlesForArticlesRelatedArticles.map((article, index) => (
            <ArticlePreviewVerticalSmall
              key={article.articleHref + index}
              {...article}
              sizes="(min-width: 77.5em) 250px, (min-width: 46.875em) cacl(25vw - 50px), (min-width: 37.5em) cacl(50vw - 50px), calc(100vw - 48px)"
              truncateTitle={false}
            />
          ))}
        </CustomGrid>
      </Gutter>
    </div>
  ) : null
}
