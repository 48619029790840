'use client'

import { Gutter } from '@design-system/src/components/Gutter'
import { useSession } from 'next-auth/react'
import React from 'react'
import { css } from 'styled-system/css'

import { isUserSubscribedToNewsletter } from '../../../_utils/publication-utils'
import { BlockEmailCaptureCustomPageData, BlockViewProps } from '../../../_utils/typescript-utils'
import { EmailCapture } from '../../EmailCapture'

export const CaptureEmail: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: Omit<BlockEmailCaptureCustomPageData, 'variation'>
  }>
> = props => {
  const { data: session, status } = useSession()
  const { newsletterId } = props.blockCustomData
  const showEmailCapture =
    !isUserSubscribedToNewsletter(status, session, props.blockCustomData.newsletterId) && newsletterId

  return showEmailCapture ? (
    <Gutter css={css.raw({ bgColor: '$pri_D' })}>
      <EmailCapture {...props.blockCustomData} variation="color" session={session} status={status} />
    </Gutter>
  ) : null
}
