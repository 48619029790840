'use client'

import { Button } from '@design-system/src/components/Button'
import { Text } from '@design-system/src/components/Text'
import {
  REACTIVATE_SUBSCRIPTION_ARGS_TYPE,
  REACTIVATE_SUBSCRIPTION_RESPONSE_BODY,
} from 'app/api/reactivate-subscription/route'
import parsePhoneNumber from 'libphonenumber-js'
import { useSession } from 'next-auth/react'
import { BlockPubAccountCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import React, { useCallback, useMemo, useState } from 'react'
import { API_ROUTES_NEXT_PUBLIC_SITE, PAGES_NEXT_PUBLIC_SITES } from 'src/utils/route-utils'
import { css } from 'styled-system/css'

import {
  getUserSubscription,
  getUserSubscriptionPlan,
  isUserSubscribedToPublication,
} from '../../../../_utils/publication-utils'
import { postData } from '../../../../_utils/rest-utils'
import { BlockViewProps } from '../../../../_utils/typescript-utils'
import { AccountPageUI } from '../AccountShared/AccountPageUI'
import { ActiveSubscriptionModal } from '../AccountShared/ActiveSubscriptionModal'
import { DeleteAccountModal } from '../AccountShared/DeleteAccountModal'
import { DeleteAccountSuccessModal } from '../AccountShared/DeleteAccountSuccessModal'
import { ManageAccountModal } from '../AccountShared/ManageAccountModal'

export const AccountStripe: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: BlockPubAccountCustomPageData
  }>
> = props => {
  const {
    stripePlans,
    stripeSupportPlans,
    propayPlans,
    connectedSubscriptionSystem,
    updateSubscriberWithStripeAccountApiUrl,
    customerServiceNumber,
    supportPlanLinkOverride,
  } = props.blockCustomData
  const { data: session, status, update } = useSession()
  const [isManageAccountModalOpen, setIsManageAccountModalOpen] = useState<boolean>(false)
  const [isActiveSubscriptionModalOpen, setIsActiveSubscriptionModalOpen] = useState<boolean>(false)
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState<boolean>(false)
  const [isDeleteAccountSuccessModalOpen, setIsDeleteAccountSuccessModalOpen] = useState<boolean>(false)
  const [updateAccountApiCallInProgress, setUpdateAccountApiCallInProgress] = useState(false)
  const [reactivateAccountApiCallInProgress, setReactivateAccountApiCallInProgress] = useState(false)
  const [showReactivateAccountError, setshowReactivateAccountError] = useState(false)
  const [showAccountFailureErrorMsg, setShowAccountFailureErrorMsg] = useState(false)
  const [showUpdateAccountError, setShowUpdateAccountError] = useState(false)

  const subscriber = session?.user
  const userHasCanceledAtPeriodEnd = subscriber?.stripeSubscriptions?.[0]?.cancel_at_period_end

  const subscriptionUserPlan = getUserSubscriptionPlan({
    session,
    stripePlans,
    stripeSupportPlans,
    propayPlans,
    connectedSubscriptionSystem,
  })

  const userSubscription = getUserSubscription({ session, connectedSubscriptionSystem })

  const userIsSubscribedToPublication = isUserSubscribedToPublication({
    status,
    session,
    stripePlans,
    stripeSupportPlans,
    connectedSubscriptionSystem,
  })

  const subscriptionInfo = useMemo<string | undefined>(() => {
    if (
      subscriptionUserPlan?.subscription &&
      subscriptionUserPlan?.system === 'STRIPE' &&
      connectedSubscriptionSystem === 'STRIPE' &&
      userSubscription?.system === 'STRIPE'
    ) {
      if (subscriptionUserPlan?.type === 'support') return subscriptionUserPlan.subscription.name
      const subscriptionName = subscriptionUserPlan.subscription.name
      const subscriptionPriceInCents = userSubscription.subscription?.amount
      const subscriptionPriceFormatted = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
        subscriptionPriceInCents / 100,
      )
      const subscriptionFrequencyCount =
        userSubscription?.subscription.interval_count > 1 ? ` ${userSubscription?.subscription.interval_count}` : ''
      const subscriptionFrequency = userSubscription?.subscription.interval
      return `${subscriptionName} ${subscriptionPriceFormatted} /${subscriptionFrequencyCount} ${subscriptionFrequency}`
    }
    return 'No Subscription Selected'
  }, [subscriptionUserPlan, userSubscription])

  const counponInfo = useMemo<string | undefined>(() => {
    const userSubscriptionDiscount = session?.user?.stripeSubscriptions?.[0]?.discount?.coupon
    if (connectedSubscriptionSystem === 'STRIPE' && userSubscriptionDiscount) {
      if (userSubscriptionDiscount.percent_off) return `${userSubscriptionDiscount.percent_off} % Discount Applied`
      if (userSubscriptionDiscount.amount_off)
        return `${Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
          userSubscriptionDiscount.amount_off / 100,
        )} Discount Applied`
    }
    return undefined
  }, [])

  const customerServiceNumberFormatted = customerServiceNumber
    ? parsePhoneNumber(customerServiceNumber)?.formatNational()
    : ''

  const customerServiceNumberURI = customerServiceNumber ? parsePhoneNumber(customerServiceNumber)?.getURI() : ''

  const onUpdateAccount = async () => {
    setUpdateAccountApiCallInProgress(true)
    setShowUpdateAccountError(false)
    try {
      const { url, error } = await postData<
        undefined,
        {
          url: string
          error: string
        }
      >({
        url: updateSubscriberWithStripeAccountApiUrl,
        data: undefined,
      })
      if (error) {
        throw new Error('Error changing subscription.')
      }
      window.location.assign(url)
    } catch (error) {
      setShowUpdateAccountError(true)
    } finally {
      setUpdateAccountApiCallInProgress(false)
    }
  }

  const handleReactivateSubscription = async () => {
    setReactivateAccountApiCallInProgress(true)
    setshowReactivateAccountError(false)
    try {
      const data = new URLSearchParams()
      data.append('subscriptionId', session?.user?.stripeSubscriptions?.[0]?.id || '')
      const res = await postData<REACTIVATE_SUBSCRIPTION_ARGS_TYPE, REACTIVATE_SUBSCRIPTION_RESPONSE_BODY>({
        url: API_ROUTES_NEXT_PUBLIC_SITE.reactivateSubscriptionApiUrl,
        data: {
          subscriptionId: session?.user?.stripeSubscriptions?.[0]?.id || '',
        },
      })
      await update()
      console.log('res', res)
      console.log('session', session)
    } catch (error) {
      setshowReactivateAccountError(true)
    } finally {
      setReactivateAccountApiCallInProgress(false)
    }
  }

  const onUserWishesToDeleteAccount = () => {
    if (subscriptionUserPlan) {
      setIsActiveSubscriptionModalOpen(true)
    } else {
      setIsDeleteAccountModalOpen(true)
    }
    setIsManageAccountModalOpen(false)
  }

  const onClickProfile = useCallback(() => {
    setIsManageAccountModalOpen(true)
  }, [])

  return (
    <>
      <AccountPageUI
        {...props.blockCustomData}
        onClickProfile={onClickProfile}
        manageSubscriptionUrl={PAGES_NEXT_PUBLIC_SITES.manageSubscriptionUrl}
        userIsSubscribedToPublication={userIsSubscribedToPublication}
        userEmail={subscriber?.email}
        userIsSubscribedToSupportPlan={subscriptionUserPlan?.type === 'support'}
        subscriptionInfo={subscriptionInfo}
        counponInfo={counponInfo}
        customerServiceNumberFormatted={customerServiceNumberFormatted}
        userHasCanceledAtPeriodEnd={userHasCanceledAtPeriodEnd}
        onReactivateSubscriptionClick={handleReactivateSubscription}
        showReactivateAccountError={showReactivateAccountError}
        reactivateAccountApiCallInProgress={reactivateAccountApiCallInProgress}
        supportPlanLinkOverride={supportPlanLinkOverride}
      />

      {/* MANAGE ACCOUNT MODAL */}
      <ManageAccountModal
        modalIsOpen={isManageAccountModalOpen}
        onCloseModalCallback={setIsManageAccountModalOpen}
        onUserWishesToDeleteAccount={onUserWishesToDeleteAccount}>
        {userIsSubscribedToPublication && (
          <>
            <Text variant="subtitle1" css={{ color: '$gs12', textAlign: 'center', pb: '$4' }}>
              Update your Payment Information or&nbsp;Address
            </Text>
            {subscriptionUserPlan?.type === 'support' ? (
              <Text variant="body1" css={{ textAlign: 'center' }}>
                Please note that you are on a support plan. To update your payment information, please contact customer
                service
                {customerServiceNumberURI && customerServiceNumberFormatted ? (
                  <>
                    {' '}
                    at{' '}
                    <a
                      href={customerServiceNumberURI}
                      className={css({
                        color: '$pri',
                      })}>
                      {customerServiceNumberFormatted}
                    </a>
                    .
                  </>
                ) : (
                  '.'
                )}
              </Text>
            ) : (
              <Button
                variant="tertiary"
                label="Update Account Details"
                fullWidth={true}
                onClick={onUpdateAccount}
                buttonState={updateAccountApiCallInProgress ? 'waiting' : 'default'}
              />
            )}
            {showUpdateAccountError && (
              <Text variant="caption" css={{ color: '$ale', textAlign: 'center' }}>
                An error occurred while trying to open your account details. Please try again later.
              </Text>
            )}
          </>
        )}
      </ManageAccountModal>

      {/* CAN'T DELETE ACCOUNT --SUBSCRIPTION ACTIVE-- MODAL  */}
      <ActiveSubscriptionModal
        modalIsOpen={isActiveSubscriptionModalOpen}
        onCloseModalCallback={setIsActiveSubscriptionModalOpen}
      />

      {/* DELETE ACCOUNT SUCCESS MODAL */}
      <DeleteAccountSuccessModal
        modalIsOpen={isDeleteAccountSuccessModalOpen}
        onCloseModalCallback={setIsDeleteAccountSuccessModalOpen}
      />

      {/* DELETE ACCOUNT MODAL */}
      <DeleteAccountModal
        modalIsOpen={isDeleteAccountModalOpen}
        showAccountFailureErrorMsg={showAccountFailureErrorMsg}
        setIsDeleteAccountModalOpen={setIsDeleteAccountModalOpen}
        setIsDeleteAccountSuccessModalOpen={setIsDeleteAccountSuccessModalOpen}
        setShowAccountFailureErrorMsg={setShowAccountFailureErrorMsg}
      />
    </>
  )
}
